<template>
    <v-app>
        <PublicHeader app></PublicHeader>
        <v-main>
            <v-sheet class="py-3 vh-50" color="dark_promolider">
                <!-- <h1 class="text-center text-white">¡ Registrate gratis !</h1> -->
                <v-row class="d-flex justify-center">
                    <v-col cols="7" align="center">
                        <h2 class="fw-bolder text-green fs-xl">
                            ¡ Registrate gratis !
                        </h2>
                        <p class="fs-4 text-gray">
                            Aprovecha de todas las master classes que tenemos
                            para ti y tu equipo de trabajo.
                        </p>
                    </v-col>
                </v-row>

                <v-container fluid>
                    <v-row>
                        <v-col cols="6">
                            <v-card
                                class="mx-auto px-6 py-8 my-auto"
                                max-width="344"
                            >
                                <v-form
                                    v-model="disable"
                                    @submit.prevent="onSubmit"
                                    ref="form"
                                >
                                    <v-text-field
                                        prepend-icon="mdi-video"
                                        v-model="masterClass.title"
                                        :readonly="loading"
                                        :rules="[required]"
                                        class="mb-2"
                                        readonly
                                    ></v-text-field>
                                    <v-text-field
                                        prepend-icon="mdi-account"
                                        v-model="form.fullname"
                                        :readonly="loading"
                                        :rules="[required]"
                                        class="mb-2"
                                        clearable
                                        label="Nombre y apellidos"
                                        placeholder="Nombre y apellidos completos"
                                    ></v-text-field>

                                    <v-text-field
                                        prepend-icon="mdi-email"
                                        v-model="form.email"
                                        :readonly="loading"
                                        :rules="[required]"
                                        class="mb-2"
                                        clearable
                                        label="Correo electrónico"
                                        placeholder="Ingresa tu correo electrónico"
                                    ></v-text-field>

                                    <v-text-field
                                        prepend-icon="mdi-phone"
                                        v-model="form.phone"
                                        :readonly="loading"
                                        :rules="[required]"
                                        class="mb-2"
                                        clearable
                                        label="Celular"
                                        placeholder="Ej. 51985656845"
                                    ></v-text-field>

                                    <v-alert
                                        v-if="alert.show"
                                        variant="outlined"
                                        :type="alert.type"
                                        class="mt-1 mb-5"
                                    >
                                        {{ alert.message }}
                                    </v-alert>
                                    <v-btn
                                        :disabled="!disable"
                                        :loading="loading"
                                        block
                                        color="success"
                                        size="large"
                                        type="submit"
                                        variant="elevated"
                                    >
                                        Registrarme
                                    </v-btn>
                                </v-form>
                            </v-card>
                        </v-col>
                        <v-col cols="6">
                            <v-img
                                :src="bannerSelected"
                                class="mx-auto mt-2"
                                height="400"
                                width="400"
                            ></v-img>
                        </v-col>
                    </v-row>
                </v-container>
            </v-sheet>
        </v-main>

        <PublicFooter app></PublicFooter>
    </v-app>
</template>

<script>
import { apiAxios, mainAxios } from "@/axios.config";
import PublicHeader from "@/components/partials/Header.vue";
import PublicFooter from "@/components/partials/Footer.vue";

export default {
    mounted() {},
    components: {
        PublicHeader,
        PublicFooter,
    },
    data: () => ({
        disable: false,
        masterClass: {},
        form: {
            masterClassId: "",
        },
        loading: false,
        showIcon: false,
        alert: {
            show: false,
            message: "",
            type: "",
        },
        masterClasses: [],
        masterClassIdRules: [(v) => !!v || "Debe seleccionar un master class"],
        productor: {},
        bannerSelected: "",
    }),
    mounted() {
        this.getInformation();
    },
    methods: {
        changeBanner: function (object) {
            this.bannerSelected = object.banner;
        },
        getInformation: async function () {
            try {
                let url = location.href;
                const { data, status } = await apiAxios.post(`/mc/show`, {
                    url,
                });
                if (status === 200) {
                    this.masterClass = data.mc;
                    this.form.masterClassId = data.mc.id;
                    this.bannerSelected = data.mc.banner;
                    this.productor = data.productor;
                }
            } catch (error) {
                const { status } = await { ...error.response };
                this.alert.show = true;
                this.validateHttpResponse(status);
            }
        },

        onSubmit() {
            if (!this.form) return;
            this.loading = true;
            this.subscribe();
        },
        required(v) {
            return !!v || "Este campo es requerido";
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        subscribe: async function () {
            try {
                const { data, status } = await apiAxios.post(
                    "mc/participant/subscribe",
                    this.form
                );
                if (status === 200) {
                    this.form.fullname = "";
                    this.form.email = "";
                    this.form.phone = "";
                    this.resetValidation();
                    this.alert.show = true;
                    this.alert.message = data.message;
                    this.alert.type = "success";
                    setTimeout(() => {
                        this.alert.show = false;
                    }, 3000);
                }
            } catch (error) {
                const { status } = await { ...error.response };
                this.validateHttpResponse(status);
            } finally {
                this.loading = false;
            }
        },
        validateHttpResponse: function (status) {
            this.alert.type = "error";

            switch (status) {
                case 401:
                    this.alert.message = "Usuario o contraseña incorrectos";

                    break;
                case 500:
                    this.alert.message = "Error interno del servidor";
                    break;
                case 422:
                    this.alert.message = "Error de validación";
                    break;
                case 404:
                    this.alert.message = "No se encontró el recurso";
                    break;
                default:
                    this.alert.message =
                        "En estos momentos no podemos procesar su solicitud";
                    break;
            }
            this.alert.show = true;
        },
    },
};
</script>
<style></style>
