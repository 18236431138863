<template>
<v-app>
    <PublicHeader app :routes="routes"></PublicHeader>
    <v-main>
        <v-sheet color="dark_promolider">
            <v-container fluid>

                <div v-if="this.sliderHeight == false" class="mt-5 row">
                    <div class="col-6">
                        <v-card color="dark_promolider" class="ml-5" outlined>
                            <v-card-title>
                                <h1 class="fw-bolder text-green" style="word-break: normal; font-size: 60px;">
                                    Herramienta de prospección masiva
                                </h1>
                            </v-card-title>

                            <v-card-text class="text-white fs-5 lh-lg">
                                <ul>
                                    <li>Más de mil masterclasses gratuitas para captar clientes. </li>
                                    <li>Atrae prospectos calificados.</li>
                                    <li>Despierta el interés de clientes potenciales.</li>
                                    <li>Tu propio embudo de marketing.</li>
                                    <li>Haz crecer tu negocio de forma exponencial copiando y pegando.</li>
                                </ul>
                                <!-- <p>¿Eres emprendedor?</p>
                                    <p>
                                        ¿Tienes en mente lanzar un proyecto
                                        online pero no sabes por dónde empezar?
                                    </p>
                                    <p>
                                        Aprende los pasos necesarios para hacer
                                        un plan de marketing digital para tu
                                        proyecto o negocio y los aspectos a
                                        tener en cuenta para tener éxito en
                                        internet.
                                    </p> -->
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="d-flex align-items-center col-6">
                        <v-img :src="require(`@assets/images/1.webp`)" class="white--text img-fluid" height="400px">
                        </v-img>
                    </div>
                </div>

                <div v-if="this.sliderHeight == true" class="mt-5 row">
                    <h1 class="fw-bolder text-green fs-1 order-1 text-center">
                        Herramienta de prospección masiva
                    </h1>
                    <div class="col-12 order-3">
                        <v-card color="dark_promolider" outlined>
                            <v-card-text class="text-white fs-5 lh-lg">
                                <ul>
                                    <li>Más de mil masterclasses gratuitas para captar clientes. </li>
                                    <li>Atrae prospectos calificados.</li>
                                    <li>Despierta el interés de clientes potenciales.</li>
                                    <li>Tu propio embudo de marketing.</li>
                                    <li>Haz crecer tu negocio de forma exponencial copiando y pegando.</li>
                                </ul>
                                <!-- <p>¿Eres emprendedor?</p>
                                    <p>
                                        ¿Tienes en mente lanzar un proyecto
                                        online pero no sabes por dónde empezar?
                                    </p>
                                    <p>
                                        Aprende los pasos necesarios para hacer
                                        un plan de marketing digital para tu
                                        proyecto o negocio y los aspectos a
                                        tener en cuenta para tener éxito en
                                        internet.
                                    </p> -->
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="d-flex align-items-center col-12 order-2">
                        <v-img :src="require(`@assets/images/1.webp`)" class="white--text img-fluid">
                        </v-img>
                    </div>
                </div>

                <div class="d-flex justify-center" style="margin-top: 100px">
                    <div cols="5" align="center">
                        <h2 class="fw-bolder text-green fs-xl">
                            ¡Marca la Diferencia!
                        </h2>
                        <p class="fs-4 text-gray">
                            "Muchos te diran lo que tienes que hacer...
                        </p>
                        <p class="fs-4 text-gray">
                            pero pocos, cómo hacerlo."
                        </p>
                    </div>
                </div>

                <div v-if="this.sliderHeight == false" class="row">
                    <div class="col-6">
                        <v-card color="dark_promolider" class="ml-5" outlined>
                            <v-card-title>
                                <h2 class="fw-bolder fs-1 text-green" style="word-break: normal;">
                                    Beneficios de la herramienta
                                </h2>
                            </v-card-title>

                            <v-card-text class="text-white fs-5 lh-lg">
                                <ul>
                                    <li>Nunca te faltarán prospectos.</li>
                                    <li>Olvídate de estar tocando puertas o estar invitando.</li>
                                    <li>Haz seguimiento a tus prospectos automáticamente.</li>
                                    <li>Clasifica a tus clientes según su interés.</li>
                                    <li>Apaláncate con los productos educativos de alto valor de nuestros productores.</li>
                                    <li>Siempre tendrás algo que entregar de forma gratuita a tus prospectos
                                        (talleres, masterclass, pdf, ebook ).</li>
                                    <!-- <li>
                                            Aprenderás los elementos necesarios
                                            para crear un plan de marketing
                                            digital y tener éxito con tu
                                            proyecto en Internet.
                                        </li>
                                        <li>
                                            Conocerás cómo hacer un análisis de
                                            situación de tu negocio.
                                        </li>
                                        <li>
                                            Establecerás con claridad tus
                                            objetivos de marketing digital.
                                        </li>
                                        <li>
                                            Conocerás cómo diseñar una
                                            estrategia de marketing online
                                            efectiva.
                                        </li>
                                        <li>
                                            Incrementarás el éxito de tus
                                            acciones aplicando las tácticas
                                            adecuadas.
                                        </li> -->
                                </ul>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="col-6">
                        <v-img :src="require(`@assets/images/2.webp`)" height="500px" contain>
                        </v-img>
                    </div>
                </div>

                <div v-if="this.sliderHeight == true" class="row d-flex justify-center">
                    <h2 class="fw-bolder text-green  fs-1 order-1 text-center">
                        Beneficios de la herramienta
                    </h2>
                    <div class="col-12 order-3">
                        <v-card color="dark_promolider" class="ml-5" outlined>
                            <v-card-text class="text-white fs-5 lh-lg">
                                <ul>
                                    <li>Nunca te faltarán prospectos.</li>
                                    <li>Olvídate de estar tocando puertas o estar invitando.</li>
                                    <li>Haz seguimiento a tus prospectos automáticamente.</li>
                                    <li>Clasifica a tus clientes según su interés.</li>
                                    <li>Apaláncate con los productos educativos de alto valor de nuestros productores.</li>
                                    <li>Siempre tendrás algo que entregar de forma gratuita a tus prospectos
                                        (talleres, masterclass, pdf, ebook ).</li>
                                    <!-- <li>
                                            Aprenderás los elementos necesarios
                                            para crear un plan de marketing
                                            digital y tener éxito con tu
                                            proyecto en Internet.
                                        </li>
                                        <li>
                                            Conocerás cómo hacer un análisis de
                                            situación de tu negocio.
                                        </li>
                                        <li>
                                            Establecerás con claridad tus
                                            objetivos de marketing digital.
                                        </li>
                                        <li>
                                            Conocerás cómo diseñar una
                                            estrategia de marketing online
                                            efectiva.
                                        </li>
                                        <li>
                                            Incrementarás el éxito de tus
                                            acciones aplicando las tácticas
                                            adecuadas.
                                        </li> -->
                                </ul>
                            </v-card-text>
                        </v-card>
                    </div>
                    <div class="col-8 order-2">
                        <v-img :src="require(`@assets/images/2.webp`)">
                        </v-img>
                    </div>
                </div>

                <div class="d-flex justify-center" style="margin-top: 100px">
                    <div cols="7" align="center">
                        <h2 class="fw-bolder text-green fs-xl">
                            Próximas Master Classes
                        </h2>
                        <p class="fs-4 text-gray">
                            Aprovecha al máximo todos los conocimientos de
                            nuestros expositores
                        </p>
                    </div>
                </div>

                <div>
                    <div v-if="upcoming.length === 0" cols="12" class="d-flex justify-center">
                        <h2 class="fw-bolder text-white">
                            No hay master classes para este mes
                        </h2>
                    </div>
                    <div v-else class="onHover" :class="index % 2 == 0 ? 'offset-md-1' : ''" cols="5" v-for="(item, index) in upcoming" :key="index">
                        <v-card class="mx-auto" max-width="440px" color="#2b3233">
                            <v-img :src="item.banner" contain max-height="440px" min-height="440px"></v-img>

                            <v-card-title class="text-white fw-bolder">
                                {{ item.title }}
                            </v-card-title>

                            <v-card-subtitle class="text-gray">
                                {{
                                        $moment(item.date).format(
                                            "DD MMMM hh:mm A"
                                        )
                                    }}
                            </v-card-subtitle>
                        </v-card>
                    </div>

                    <div cols="12" class="d-flex justify-center">
                        <v-btn color="green" class="mt-5" @click="goToMasterClasses()">
                            Ver más
                        </v-btn>
                    </div>
                </div>
            </v-container>
        </v-sheet>
    </v-main>
    <PublicFooter app></PublicFooter>
</v-app>
</template>

<script>
import {
    apiAxios
} from "@/axios.config";
import PublicHeader from "@/components/partials/Header.vue";
import PublicFooter from "@/components/partials/Footer.vue";

export default {
    props: {
        homeRoute: {
            type: String,
            default: "/",
        },
        loginRoute: {
            type: String,
            default: "/login",
        },
    },
    mounted() {
        this.recentMasterClasses();
    },
    computed: {
        routes() {
            return {
                home: this.homeRoute,
                login: this.loginRoute,
            };
        },
    },
    components: {
        PublicHeader,
        PublicFooter,
    },
    name: "Home",
    data: () => ({
        upcoming: [],
        sliderHeight: false,
    }),
    methods: {
        screen() {
            let screenWidth = screen.width;
            if (screenWidth < 576) {
                this.sliderHeight = true;
            } else if (screenWidth >= 576 && screenWidth < 768) {
                this.sliderHeight = true;
            } else if (screenWidth >= 768) {
                this.sliderHeight = false;
            }
        },
        recentMasterClasses: async function () {
            const {
                status,
                data
            } = await apiAxios.get("/mc/upcoming");
            if (status === 200) {
                this.upcoming = data.list;
            }
        },
        goToMasterClasses: function () {
            // redirect to master classes
            location.href = "/master-classes";
        },
    },
    mounted() {
        this.screen();
    },
};
</script>

<style>
.text-green {
    color: var(--v-green-base) !important;
}

.text-gray {
    color: var(--v-gray-base) !important;
}

.fs-xl {
    font-size: 3.7em;
}

.onHover:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
}
</style>
