require('./bootstrap');


import Vue from 'vue';
Vue.config.devtools = true;
window.Vue = require('vue');
import vuetify from './vuetify'

import LoginForm from './components/Auth/LoginForm.vue';
import moment from "moment";

window.moment = moment;
 
moment.locale('es');
Vue.prototype.$moment = moment;
import VueSocialSharing from 'vue-social-sharing'
/**
 * Pages
 */
import Home from './components/pages/Home.vue';
import Dashboard from './components/pages/Dashboard.vue';

import MasterClassPage from './components/master_class/Page.vue';
import ProfilePage from './components/profile/Page.vue';
import InvitationPage from './components/invitation/Page.vue';
import MasterClassesPage from './components/pages/MasterClasses.vue';
import ParticipantsPage from './components/participants/Page.vue';

Vue.component('login-form', LoginForm);
Vue.component('home', Home);
Vue.component('dashboard', Dashboard);
Vue.component('master-class-page', MasterClassPage);
Vue.component('profile-page', ProfilePage);
Vue.component('invitation-page', InvitationPage);
Vue.component('master-classes-page', MasterClassesPage);
Vue.component('participants-page', ParticipantsPage);
Vue.use(VueSocialSharing)
// Vue.component('public-header', PublicHeader);
// Vue.component('public-footer', PublicFooter);
// app.component('Datepicker', Datepicker);
// Vue.component('master-class-page', MasterClassPage);
// app.component('easy-data-table', Vue3EasyDataTable);
// app.use(Steps);
// app.use(Button);
// app.use(Table);
// app.use(Tag);
// app.use(Divider);
// app.mount("#app")


const app = new Vue({
    vuetify,
    el: '#app'
});