<template>
    <v-app>
        <v-overlay
            persistent
            v-model="loading"
            class="align-center justify-center"
        >
            <v-progress-circular
                indeterminate
                size="64"
                color="primary"
            ></v-progress-circular>
        </v-overlay>

        <v-stepper v-model="current">
            <v-stepper-header>
                <v-stepper-step :complete="current > 1" step="1">
                    Información
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="current > 2" step="2">
                    Multimedia
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-form
                        class="mt-5"
                        v-model="validFirstStep"
                        ref="validFirstStep"
                        lazy-validation
                    >
                        <v-row>
                            <v-col cols="12" lg="6">
                                <v-select
                                    v-model="form.courseId"
                                    prepend-inner-icon="mdi-book"
                                    :items="courses"
                                    :item-text="(item) => item.title"
                                    :item-value="(item) => item.id"
                                    :rules="courseIdRules"
                                    label="Curso"
                                    required
                                ></v-select>
                            </v-col>

                            <v-col cols="12" lg="6">
                                <v-text-field
                                    ref="title"
                                    v-model="form.title"
                                    :rules="titleRules"
                                    label="Título"
                                    prepend-inner-icon="mdi-format-title"
                                    required
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" lg="6">
                                <v-text-field
                                    v-model="form.zoomLink"
                                    label="Link de Zoom"
                                    prepend-inner-icon="mdi-link"
                                    required
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" lg="3">
                                <v-dialog
                                    ref="dateDialog"
                                    v-model="dateModal"
                                    :return-value.sync="form.date"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="form.date"
                                            label="Seleccione una fecha"
                                            :rules="dateRules"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="form.date"
                                        scrollable
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="dateModal = false"
                                        >
                                            Cancelar
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="
                                                $refs.dateDialog.save(form.date)
                                            "
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="12" lg="3">
                                <v-dialog
                                    ref="timeDialog"
                                    v-model="timeModal"
                                    :return-value.sync="form.time"
                                    persistent
                                    width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="form.time"
                                            :rules="timeRules"
                                            label="Seleccione la hora"
                                            prepend-icon="mdi-clock-time-four-outline"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                        v-if="timeModal"
                                        v-model="form.time"
                                        full-width
                                    >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="timeModal = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="
                                                $refs.timeDialog.save(form.time)
                                            "
                                        >
                                            OK
                                        </v-btn>
                                    </v-time-picker>
                                </v-dialog>
                            </v-col>

                            <v-col cols="12" lg="12" class="mb-5 pb-5">
                                <label>Descripción</label>
                                <VueEditor
                                    v-model:content="form.description"
                                    ref="description"
                                    contentType="html"
                                    :class="{
                                        'ql-toolbar-error':
                                            !validateDescription,
                                        'ql-snow-success': validateDescription,
                                    }"
                                    placeholder="Describa el contenido del master class"
                                ></VueEditor>
                                <div
                                    class="v-input__details"
                                    v-if="!validateDescription"
                                >
                                    <div class="v-messages">
                                        <div
                                            class="v-messages__message error--text"
                                            style="
                                                transform-origin: center top 0px;
                                            "
                                        >
                                            Debe describir la master class
                                        </div>
                                    </div>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-btn color="primary" @click="next"> Siguiente </v-btn>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-form
                        class="mt-2"
                        v-model="validSecondStep"
                        ref="validSecondStep"
                        lazy-validation
                    >
                        <v-row>
                            <v-col cols="12" lg="6">
                                <v-file-input
                                    @change="onBannerChange"
                                    color="deep-purple accent-4"
                                    :rules="bannerRules"
                                    v-model="form.banner"
                                    counter
                                    accept="image/jpeg, image/png, image/jpg, image/webp"
                                    label="Banner"
                                    prepend-icon="mdi-camera"
                                    :show-size="1000"
                                >
                                    <template
                                        v-slot:selection="{ index, text }"
                                    >
                                        <v-chip
                                            v-if="index < 2"
                                            color="deep-purple accent-4"
                                            dark
                                            label
                                            small
                                        >
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                            </v-col>

                            <v-col cols="12" lg="6">
                                <v-file-input
                                    @change="onVideoChange"
                                    v-model="form.video"
                                    color="deep-purple accent-4"
                                    counter
                                    accept="video/mp4"
                                    label="Video"
                                    placeholder="Seleccione un video"
                                    prepend-icon="mdi-video"
                                    :show-size="1000"
                                >
                                    <template
                                        v-slot:selection="{ index, text }"
                                    >
                                        <v-chip
                                            v-if="index < 2"
                                            color="deep-purple accent-4"
                                            dark
                                            label
                                            small
                                        >
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                            </v-col>

                            <v-col cols="12" lg="6" align="center">
                                <v-img
                                    class="bg-white"
                                    max-width="170"
                                    max-height="170"
                                    width="100%"
                                    height="100%"
                                    :aspect-ratio="1"
                                    :key="url"
                                    :src="url"
                                    cover
                                    @click="toggleFullscreen(url)"
                                ></v-img>
                                <div v-if="zoomImage" class="overlay">
                                    <div class="bg-dark">
                                        <p class="text-white fw-500">
                                            Haga click en la imagen para salir
                                            de la previsualización
                                        </p>
                                    </div>
                                    <v-img
                                        :width="650"
                                        :src="zoomImage"
                                        cover
                                        @click.stop="zoomImage = null"
                                    ></v-img>
                                </div>
                            </v-col>

                            <v-col cols="12" lg="6" align="center">
                                <v-img
                                    v-if="form.video.length == 0"
                                    class="bg-white"
                                    max-width="280px"
                                    max-height="190px"
                                    width="100%"
                                    height="100%"
                                    :aspect-ratio="1"
                                    :src="videoPlaceholder"
                                    cover
                                ></v-img>
                                <video
                                    id="video-preview"
                                    controls
                                    :class="{
                                        'd-none': form.video.length == 0,
                                    }"
                                    style="
                                        width: 300px;
                                        height: 240px;
                                        margin-top: -40px;
                                    "
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-btn color="primary" @click="current = 1"> Atrás </v-btn>
                    <v-btn
                        color="primary"
                        @click="store"
                        v-if="validSecondStep"
                    >
                        Crear master class
                    </v-btn>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
        <v-alert v-if="showAlert" type="success" class="custom-alert">
            {{ message }}
        </v-alert>
    </v-app>
</template>
<script>
import { apiAxios } from "@/axios.config";
import imagePlaceholder from "@assets/images/img_placeholder.webp";
import videoPlaceholder from "@assets/images/video_placeholder.webp";
import { VueEditor } from "vue2-quill-editor";

export default {
    components: {
        VueEditor,
    },
    data: () => ({
        message: "",
        showAlert: false,
        dateModal: false,
        timeModal: false,
        courses: [],
        disable: false,
        loading: false,
        current: 1,
        steps: [
            {
                title: "Información",
            },
            {
                title: "Multimedia",
            },
        ],
        form: {
            banner: [],
            video: [],
            description: "",
        },
        zoomImage: null,
        url: imagePlaceholder,
        videoPlaceholder: videoPlaceholder,
        courseIdRules: [
            (v) => !!v || "Debe seleccionar un curso para la el master class",
        ],
        titleRules: [(v) => !!v || "El título es obligatorio"],
        dateRules: [(v) => !!v || "La fecha es obligatorio"],
        timeRules: [(v) => !!v || "La hora es obligatorio"],
        bannerRules: [
            (v) => !!v || "El banner es obligatorio",
            (v) => (v && v.size > 0) || "El banner es obligatorio",
        ],
        // videoRules: [
        //     (v) => !!v || "El video es obligatorio",
        //     (v) => (v && v.size > 0) || "El video es obligatorio",
        // ],
        validFirstStep: false,
        validSecondStep: false,
        validateDescription: true,
    }),
    mounted() {
        this.getCourse();
    },
    watch: {
        "form.banner": {
            handler: function (val) {
                if (val.length == 0) {
                    this.url = imagePlaceholder;
                }
            },
        },
        "form.description": {
            handler: function (val) {
                this.valDescription(val);
            },
        },
    },
    methods: {
        next: async function () {
            if (this.current == 1) {
                const valid = await this.$refs.validFirstStep.validate();
                this.valDescription(this.form.description);
                if (valid && this.validateDescription) {
                    this.current++;
                }
            }
            if (this.current == 2) {
                const valid = await this.$refs.validSecondStep.validate();
                if (valid) this.validSecondStep = true;
            }
        },
        prev: function () {
            this.current -= 1;
        },
        valDescription: function (val) {
            if (
                !val ||
                val.trim() === "" ||
                val.length === 0 ||
                this.validateEmptyHtml(val) === false
            ) {
                this.validateDescription = false;
            } else {
                this.validateDescription = true;
            }
        },
        validateEmptyHtml: function (val) {
            const div = document.createElement("div");
            div.innerHTML = val;
            const text = div.textContent || div.innerText || "";
            if (text.trim() === "") {
                div.remove();
                return false;
            } else {
                div.remove();
                return true;
            }
        },
        toggleFullscreen: function (elem) {
            this.zoomImage = elem;
        },
        onBannerChange: function (file) {
            if (file) {
                this.url = URL.createObjectURL(file);
            }
        },
        onVideoChange: function (file) {
            if (file) {
                let video = document.getElementById("video-preview");
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    video.src = reader.result;
                    video.preload = "metadata";
                    video.onloadedmetadata = () => {
                        let seconds = video.duration;
                        this.form.duration = seconds;
                    };
                };
            }
        },
        getCourse: async function () {
            try {
                const { data, status } = await apiAxios.get("/mc/course/list");
                if (status === 200) {
                    this.courses = data;
                }
            } catch (error) {
                console.log(error);
            }
        },
        showAlertDialog: function (message) {
            this.message = message;
            this.showAlert = true;
            setTimeout(() => {
                this.message = "";
                this.showAlert = false;
            }, 3000);
        },
        clearFields: function () {
            this.form = {
                banner: [],
                video: [],
                description: "",
            };
            this.url = imagePlaceholder;
            this.videoPlaceholder = videoPlaceholder;
            this.current = 1;
        },
        store: async function () {
            this.loading = true;
            this.disabled = true;
            let form = new FormData();
            form.append("course_id", this.form.courseId);
            form.append("title", this.form.title);
            form.append("description", this.form.description);
            form.append(
                "start_at",
                this.form.date + " " + this.form.time + ":00"
            );
            form.append("banner", this.form.banner);
            form.append("video", this.form.video);
            form.append("duration", this.form.duration);
            form.append("zoom_link", this.form.zoomLink);
            let config = { headers: { "Content-Type": "multipart/form-data" } };
            const { data, status } = await apiAxios.post(
                "mc/store",
                form,
                config
            );
            if (status === 200) {
                this.showAlertDialog(data.message);
                this.clearFields();
                this.loading = false;
                this.disabled = false;
            }
        },
    },
};
</script>
<style>
.custom-alert {
    position: fixed;
    right: 20px;
    bottom: 20px;
    margin: 0 auto;
}

.error input::placeholder {
    color: #ff5252 !important;
}
.dp__icon-error * {
    color: #ff5252 !important;
}
.outline-error * {
    border-bottom-color: #ff5252 !important;
}
.ql-toolbar-error > div:first-child {
    border: 1px solid #ff5252 !important;
    height: 100%;
    min-height: 110px;
}
.ql-snow-success {
    border: 1px solid #d1d5db;
    height: 100%;
    min-height: 110px;
}
.ql-toolbar-error > div > div {
    border: 1px solid #ff5252 !important;
    height: 100%;
    min-height: 110px;
}
.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}

.dp__theme_light {
    --dp-background-color: #eff1f3 !important;
    --dp-text-color: #212529 !important ;
    --dp-hover-color: #c3cbd2 !important;
    --dp-hover-text-color: #212529;
    --dp-hover-icon-color: #959595;
    --dp-primary-color: #1ae600 !important;
    --dp-primary-text-color: #f8f5f5;
    --dp-secondary-color: #c0c4cc;
    --dp-border-color: #ddd;
    --dp-menu-border-color: #ddd;
    --dp-border-color-hover: #aaaeb7;
    --dp-disabled-color: #f6f6f6;
    --dp-scroll-bar-background: #f3f3f3;
    --dp-scroll-bar-color: #959595;
    --dp-success-color: #1ae600 !important;
    --dp-success-color-disabled: #a3d9b1;
    --dp-icon-color: #959595;
    --dp-danger-color: #ff6f60;
    --dp-highlight-color: #212529;
}
.fw-overflow-scroll .fw-body-list:hover {
    overflow-x: hidden !important;
}
/* .anticon svg {
    margin-top: -10px !important;
}

.ant-steps-item-title::after {
    height: 10px !important;
}

.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background-color: #1be500 !important;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: #1be500 !important;
}
.ant-steps-item-finish .ant-steps-item-icon {
    border-color: #1be500 !important;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #1be500 !important;
}

.ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-title,
.ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-subtitle,
.ant-steps
    .ant-steps-item:not(.ant-steps-item-active)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-description {
    color: #1be500 !important;
}
.ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon
    .ant-steps-icon {
    color: #1be500 !important;
}
.ant-steps
    .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)
    > .ant-steps-item-container[role="button"]:hover
    .ant-steps-item-icon {
    border-color: #1ae600 !important;
}
.ant-steps-item-process .ant-steps-item-icon {
    border-color: #1be500 !important;
} */
.dp__input {
    min-height: 55px !important;
}
</style>
