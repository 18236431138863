import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
Vue.use(Vuetify)
export default new Vuetify({
    theme: {
        dark: false,
        themes: {
            light: {
                primary: '#00ff00',
                white: '#f8fafc',
                dark_promolider: '#131b1c',
                dark_light_promolider: '#212529',
                greenish: '#015457',
                background: '#ccc',
                surface: '#212121',
                'primary-darken-1': '#3700B3',
                secondary: '#03DAC5',
                'secondary-darken-1': '#03DAC5',
                error: '#CF6679',
                info: '#2196F3',
                success: '#1ae600',
                green: '#1ae600',
                gray: '#898d8e',
                warning: '#FB8C00'
            },
            dark: {
                /*
                  ? comments structure [light] - [dark]
                */
                custm_theme_1: '#000000', // white - black
                custm_theme_2: '#000000',// pink - black
                custm_theme_3: '#ffffff', // black - white
                custm_theme_4: '#ffffff', // white - white

            }

            // themes: {
            //     myCustomTheme: {
            //         colors: {
            //             primary: '#00ff00',
            //             white: '#f8fafc',
            //             dark_promolider: '#131b1c',
            //             dark_light_promolider: '#212529',
            //             greenish: '#015457',
            //             background: '#ccc',
            //             surface: '#212121',
            //             'primary-darken-1': '#3700B3',
            //             secondary: '#03DAC5',
            //             'secondary-darken-1': '#03DAC5',
            //             error: '#CF6679',
            //             info: '#2196F3',
            //             success: '#1ae600',
            //             warning: '#FB8C00'
            //         }
            //     }
            // }
        },
        options: {
            customProperties: true,
        },
    },
})
// theme: {
    //     icons: {
    //         defaultSet: 'mdi',
    //     },
    //     defaultTheme: 'myCustomTheme',
    //     themes: {
    //         myCustomTheme: {
    //             colors: {
    //                 primary: '#00ff00',
    //                 dark_promolider: '#131b1c',
    //                 dark_light_promolider: '#212529',
    //                 greenish: '#015457',
    //                 background: '#ccc',
    //                 surface: '#212121',
    //                 'primary-darken-1': '#3700B3',
    //                 secondary: '#03DAC5',
    //                 'secondary-darken-1': '#03DAC5',
    //                 error: '#CF6679',
    //                 info: '#2196F3',
    //                 success: '#1ae600',
    //                 warning: '#FB8C00'
    //             }
    //         }
    //     }
    // }