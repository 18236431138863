<template>
    <v-app>
        <PublicHeader app></PublicHeader>
        <v-main>
            <!-- implementar vue router -->
            <!-- <v-container fluid> </v-container> -->
            <v-sheet class="pa-12 vh-50" color="dark_promolider">
                <h1 class="text-center text-white mb-3">Iniciar sesión</h1>
                <v-card class="mx-auto px-6 py-8 my-auto" max-width="344">
                    <v-form
                        v-model="disable"
                        @submit.prevent="onSubmit"
                        ref="form"
                    >
                        <v-text-field
                            prepend-icon="mdi-account"
                            v-model="form.username"
                            :readonly="loading"
                            :rules="[required]"
                            class="mb-2"
                            clearable
                            label="Nombre de usuario"
                            placeholder="Ingrese su nombre de usuario"
                            hint="Ingrese su nombre de usuario para iniciar sesión"
                        ></v-text-field>

                        <v-text-field
                            :prepend-icon="showIcon ? 'mdi-eye' : 'mdi-eye-off'"
                            v-model="form.password"
                            :readonly="loading"
                            :rules="[required]"
                            :type="showIcon ? 'text' : 'password'"
                            clearable
                            label="Contraseña"
                            @click:prepend="showIcon = !showIcon"
                            autocomplete="on"
                            placeholder="Ingrese su contraseña"
                            hint="Ingrese su contraseña para iniciar sesión"
                        ></v-text-field>

                        <v-alert
                            v-if="alert.show"
                            variant="outlined"
                            type="warning"
                            class="mt-1 mb-5"
                        >
                            {{ alert.message }}
                        </v-alert>
                        <v-btn
                            :disabled="!disable"
                            :loading="loading"
                            block
                            color="success"
                            size="large"
                            type="submit"
                            variant="elevated"
                        >
                            Ingresar
                        </v-btn>
                    </v-form>
                </v-card>
            </v-sheet>
        </v-main>

        <PublicFooter app></PublicFooter>
    </v-app>
</template>

<script>
import { apiAxios, mainAxios } from "@/axios.config";
import PublicHeader from "@/components/partials/Header.vue";
import PublicFooter from "@/components/partials/Footer.vue";

export default {
    components: {
        PublicHeader,
        PublicFooter,
    },
    data: () => ({
        disable: false,
        form: {},
        loading: false,
        showIcon: false,
        alert: {
            show: false,
            message: "",
        },
    }),

    methods: {
        onSubmit() {
            if (!this.form) return;
            this.loading = true;
            this.login();
        },
        required(v) {
            return !!v || "Este campo es requerido";
        },
        saveCredentials: async function (form) {
            const { data } = await mainAxios.post("/credentials/store", form);
            localStorage.setItem("token", data.session.access_token);
            if (data.message == "success") {
                window.location.href = "/dashboard";
            }
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
        login: async function () {
            try {
                const { data } = await apiAxios.post("auth/login", this.form);
                if (data.status === 200) {
                    this.form = {};
                    this.resetValidation();
                    this.alert.show = false;
                    this.loading = false;
                    this.saveCredentials(data);
                }
            } catch (error) {
                const { status } = await { ...error.response };
                this.validateHttpResponse(status);
            } finally {
                this.loading = false;
            }
        },
        validateHttpResponse: function (status) {
            switch (status) {
                case 401:
                    this.alert.message = "Usuario o contraseña incorrectos";
                    break;
                case 500:
                    this.alert.message = "Error interno del servidor";
                    break;
                case 422:
                    this.alert.message = "Error de validación";
                    break;
                case 404:
                    this.alert.message = "No se encontró el recurso";
                    break;
                default:
                    this.alert.message =
                        "En estos momentos no podemos procesar su solicitud";
                    break;
            }
            this.alert.show = true;
        },
    },
};
</script>
<style></style>
