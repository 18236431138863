<template>
    <v-app>
        <v-container fluid>
            <v-card class="mx-auto mt-5" tile>
                <v-img
                    height="200"
                    src="https://media-exp1.licdn.com/dms/image/C4E16AQGMvM36fXL9qw/profile-displaybackgroundimage-shrink_350_1400/0/1658424502860?e=1676505600&v=beta&t=vLG2cWRKt2NvCzcrFwhJzQ7pcfMYETm3BkGAAovPrnI"
                ></v-img>
                <v-row style="margin: 2.5%; position: absolute; top: 130px">
                    <v-list-item>
                        <v-list-item-avatar size="100">
                            <img :src="user.photo" alt="John" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title
                                style="margin-top: 35px; margin-left: 10px"
                                >{{ user.fullName }}</v-list-item-title
                            >
                            <v-list-item-subtitle style="margin-left: 10px"
                                >{{ membership }} |
                                {{ role }}</v-list-item-subtitle
                            >
                        </v-list-item-content>
                    </v-list-item>
                </v-row>
            </v-card>
        </v-container>

        <v-container style="margin-top: 80px">
            <v-row>
                <!-- <v-col cols="12" align="center">
                    <v-text-field
                        ref="link"
                        v-model="form.link"
                        label="Presione el botón para generar el enlace de invitación"
                        readonly
                        :disabled="!linkStatus"
                        append-icon="mdi-content-copy"
                        @click:append="copyLink"
                    ></v-text-field>
                    <v-btn
                        rounded
                        @click="generate"
                        :loading="loading"
                        :disabled="linkStatus"
                    >
                        Generar enlace
                    </v-btn>
                </v-col> -->
            </v-row>
        </v-container>
<!-- 
        <v-snackbar v-model="snackbar" :timeout="timeout">
            Enlace copiado al portapapeles
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar> -->
    </v-app>
</template>
<script>
import { apiAxios } from "@/axios.config";

export default {
    props: {
        user: Object,
    },
    name: "ProfilePage",
    data() {
        return {
            snackbar: false,
            timeout: 2500,
            loading: false,
            form: {
                link: "",
            },
            linkStatus: null,
            accountType: [
                "Administrator",
                "School",
                "Academy",
                "University",
                "Basic",
                "Guest",
            ],
            roles: ["Administrator", "Producer", "Distributor"],
        };
    },
    computed: {
        membership() {
            return this.accountType[this.user.id_account_type];
        },
        role() {
            return this.roles[this.user.roles[0].id];
        },
    },
    mounted() {
        // this.checkIfInvitationLinkExists();
    },
    watch: {
        // linkStatus: function (newVal) {
        //     if (newVal) {
        //         this.getInvitationLink();
        //     }
        // },
    },
    methods: {
        // copyLink() {
        //     if (window.isSecureContext && navigator.clipboard) {
        //         // protocol is https
        //         navigator.clipboard.writeText(this.form.link);
        //     } else {
        //         // protocol is http
        //         this.unsecuredCopyToClipboard(this.form.link);
        //     }
        //     this.showSnackbar();
        // },
        // unsecuredCopyToClipboard(content) {
        //     const textArea = document.createElement("textarea");
        //     textArea.value = content;
        //     document.body.appendChild(textArea);
        //     textArea.focus();
        //     textArea.select();
        //     try {
        //         document.execCommand("copy");
        //     } catch (err) {
        //         console.error("Fallback: Oops, unable to copy", err);
        //     }
        //     document.body.removeChild(textArea);
        // },

        // showSnackbar() {
        //     this.snackbar = true;
        // },

        // generate: async function () {
        //     try {
        //         this.loading = true;
        //         const { data, status } = await apiAxios.post(
        //             `mc/invitation-link/generate`,
        //             {
        //                 id: this.user.id,
        //             }
        //         );
        //         if (status === 200) {
        //             this.checkIfInvitationLinkExists();
        //             this.form.link = data.link;
        //             this.loading = false;
        //         }
        //     } catch (error) {
        //         this.loading = false;
        //         console.log(error);
        //     }
        // },

        // checkIfInvitationLinkExists: async function () {
        //     try {
        //         const { data, status } = await apiAxios.get(
        //             `mc/invitation-link/check/${this.user.id}`
        //         );
        //         if (status === 200) {
        //             this.linkStatus = data.exists;
        //         }
        //     } catch (error) {
        //         console.log(error);
        //     }
        // },

        // getInvitationLink: async function () {
        //     try {
        //         const { data, status } = await apiAxios.get(
        //             `mc/invitation-link/myInvitationLink/${this.user.id}`
        //         );
        //         if (status === 200) {
        //             this.form.link = data.link;
        //         }
        //     } catch (error) {
        //         console.log(error);
        //     }
        // },
    },
};
</script>
