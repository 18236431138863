<template>
    <v-app>
        <v-container class="pa-4" fluid="fluid" grid-list-md="grid-list-md">
            <v-layout wrap="wrap">
                <v-flex xs12="xs12" class="my-5">
                    <h1 class="display-1 mb-1">Dashboard</h1>
                </v-flex>
                <v-row>
                    <v-col
                        v-for="(number, index) in numbers"
                        :key="index"
                        cols="12"
                        sm="6"
                        md="3"
                    >
                        <v-card class="text-xs-center" height="100%">
                            <v-card-text>
                                <div class="display-1 mb-2">
                                    {{ number }}
                                </div>
                                {{ labels[index] }}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>

                <!-- <v-flex xs12="xs12">
                    <v-card>
                        <v-card-title class="grey darken-4"
                            >New Leads
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="newLeadsSearch"
                                append-icon="search"
                                label="Search"
                            ></v-text-field>
                        </v-card-title>
                        <v-data-table
                            :headers="newLeadsHeaders"
                            :items="newLeads"
                            :search="newLeadsSearch"
                        >
                            <template slot="items" slot-scope="props">
                                <td>
                                    {{ props.item.firstName }}
                                    {{ props.item.lastName }}
                                </td>
                                <td>{{ props.item.email }}</td>
                                <td>{{ props.item.company }}</td>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-flex> -->
            </v-layout>
        </v-container>
    </v-app>
</template>
<script>
import { apiAxios } from "@/axios.config";

export default {
    data() {
        return {
            labels: [],
            numbers: [],
            newLeadsSearch: "",
            stats: [],
            tasks: [
                {
                    id: 0,
                    title: "Book meeting for Thursday",
                },
                {
                    id: 1,
                    title: "Review new leads",
                },
                {
                    id: 2,
                    title: "Be awesome!",
                },
            ],
            newLeads: [
                {
                    firstName: "Giselbert",
                    lastName: "Hartness",
                    email: "ghartness0@mail.ru",
                    company: "Kling LLC",
                },
                {
                    firstName: "Honey",
                    lastName: "Allon",
                    email: "hallon1@epa.gov",
                    company: "Rogahn-Hermann",
                },
                {
                    firstName: "Tommy",
                    lastName: "Rickards",
                    email: "trickards2@timesonline.co.uk",
                    company: "Kreiger, Wehner and Lubowitz",
                },
                {
                    firstName: "Giffy",
                    lastName: "Farquharson",
                    email: "gfarquharson3@goo.gl",
                    company: "Heathcote-Funk",
                },
                {
                    firstName: "Rahel",
                    lastName: "Matches",
                    email: "rmatches4@sfgate.com",
                    company: "Maggio, Russel and Feeney",
                },
                {
                    firstName: "Krystal",
                    lastName: "Natte",
                    email: "knatte5@opera.com",
                    company: "Sanford-Feeney",
                },
                {
                    firstName: "Ronnica",
                    lastName: "Galliver",
                    email: "rgalliver6@epa.gov",
                    company: "Schaefer Group",
                },
                {
                    firstName: "Jenny",
                    lastName: "Bugge",
                    email: "jbugge7@independent.co.uk",
                    company: "Gutmann, Miller and Prosacco",
                },
                {
                    firstName: "Tracee",
                    lastName: "Viscovi",
                    email: "tviscovi8@techcrunch.com",
                    company: "Anderson, Kohler and Renner",
                },
                {
                    firstName: "Teodor",
                    lastName: "Fitzsimmons",
                    email: "tfitzsimmons9@mediafire.com",
                    company: "Durgan-Kovacek",
                },
            ],
            newLeadsHeaders: [
                {
                    text: "Name",
                    value: "firstName",
                },
                {
                    text: "Email",
                    value: "email",
                },
                {
                    text: "Company",
                    value: "company",
                },
            ],
            drawer: true,
            mainMenu: {
                dashboard: "Dashboard",
                people: "Leads",
                business: "Companies",
                business_center: "Deals",
                file_copy: "Invoices",
                settings: "Settings",
            },
        };
    },
    mounted() {
        this.indicators();
    },
    methods: {
        indicators: async function () {
            const { data, status } = await apiAxios.get("/mc/indicators");
            const { labels, numbers } = await data.list;
            if (status === 200) {
                this.labels = labels;
                this.numbers = numbers;
            }
        },

        clickToggleDrawer() {
            this.drawer = !this.drawer;
        },
        clickDeleteTask(task) {
            const i = this.tasks.indexOf(task);
            this.tasks.splice(i, 1);
        },
    },
};
</script>
