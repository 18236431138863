<template>
    <v-app>
        <v-card>
            <v-card-title>
                Master Class recientes
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-1"
                :search="search"
                loading-text="Cargando..."
                no-data-text="No se encontraron resultados"
                :loading="loading"
            >
                <template v-slot:item.start_at="{ item }">
                    <span> {{ $moment(item).format("DD MMMM hh:mm A") }}</span>
                </template>
                <template v-slot:item.invitation_link="{ item }">
                    <span @click="copyLink(item.invitation_link)" style="cursor: pointer;"> {{ item.invitation_link }}</span>
                </template>
                <!-- Edit -->
                <template v-slot:top>
                    <v-dialog v-model="dialog" max-width="500px">
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-container>
                                    <v-row>
                                        <v-col cols="6" sm="6" md="6">
                                            <v-select
                                                v-model="editedItem.course_id"
                                                prepend-inner-icon="mdi-book"
                                                :items="courses"
                                                :item-text="
                                                    (item) => item.title
                                                "
                                                :item-value="(item) => item.id"
                                                :rules="courseIdRules"
                                                label="Curso"
                                                required
                                            ></v-select>
                                        </v-col>
                                        <v-col cols="6" sm="6" md="6">
                                            <v-text-field
                                                v-model="editedItem.title"
                                                label="Título"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6" sm="6" md="6">
                                            <v-menu
                                                ref="timeMenu"
                                                v-model="timeMenu"
                                                :close-on-content-click="false"
                                                :return-value.sync="
                                                    editedItem.time
                                                "
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            editedItem.time
                                                        "
                                                        label="Picker in menu"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                    v-model="editedItem.time"
                                                    no-title
                                                    scrollable
                                                >
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="
                                                            timeMenu = false
                                                        "
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="
                                                            $refs.timeMenu.save(
                                                                editedItem.time
                                                            )
                                                        "
                                                    >
                                                        OK
                                                    </v-btn>
                                                </v-time-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="6" sm="6" md="6">
                                            <v-menu
                                                ref="dateMenu"
                                                v-model="dateMenu"
                                                :close-on-content-click="false"
                                                :return-value.sync="
                                                    editedItem.date
                                                "
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                            >
                                                <template
                                                    v-slot:activator="{
                                                        on,
                                                        attrs,
                                                    }"
                                                >
                                                    <v-text-field
                                                        v-model="
                                                            editedItem.date
                                                        "
                                                        label="Picker in menu"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on"
                                                    ></v-text-field>
                                                </template>
                                                <v-date-picker
                                                    v-model="editedItem.date"
                                                    no-title
                                                    scrollable
                                                >
                                                    <v-spacer></v-spacer>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="
                                                            dateMenu = false
                                                        "
                                                    >
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn
                                                        text
                                                        color="primary"
                                                        @click="
                                                            $refs.dateMenu.save(
                                                                editedItem.date
                                                            )
                                                        "
                                                    >
                                                        OK
                                                    </v-btn>
                                                </v-date-picker>
                                            </v-menu>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="warning darken-1"
                                    text
                                    @click="close"
                                >
                                    Cancelar
                                </v-btn>
                                <v-btn
                                    color="green darken-1"
                                    text
                                    @click="save"
                                >
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                            <v-card-title class="text-h5"
                                >¡Está acción no se puede revertir!
                            </v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDelete"
                                    >Cancelar</v-btn
                                >
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="deleteItemConfirm"
                                    >Continuar</v-btn
                                >
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </template>
                <!-- Delete -->
                <template v-slot:item.actions="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                        mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                        mdi-delete
                    </v-icon>
                </template>
                <!-- <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize"> Reset </v-btn>
                </template> -->
            </v-data-table>
        </v-card>

        <v-alert v-if="showAlert" type="success" class="custom-alert">
            {{ message }}
        </v-alert>

        <v-snackbar v-model="snackbar" :timeout="timeout">
            Enlace copiado al portapapeles
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="blue"
                    text
                    v-bind="attrs"
                    @click="snackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </v-app>
</template>
<script>
import { apiAxios } from "@/axios.config";
import moment from "moment";
export default {
    data() {
        return {
            dateMenu: false,
            timeMenu: false,
            timeout: 2500,
            courses: [],
            showAlert: false,
            snackbar: false,
            search: "",
            message: "",
            loading: true,
            dialog: false,
            dialogDelete: false,
            items: [],
            headers: [
                {
                    text: "id",
                    align: " d-none",
                    value: "id",
                },
                { text: "Curso", align: "start", value: "course.title" },
                { text: "Título", value: "title" },
                { text: "Fecha de inicio", value: "start_at" },
                { text: "Link de invitación", value: "invitation_link" },
                { text: "Acciones", value: "actions", sortable: false },
            ],
            editedIndex: -1,
            editedItem: {
                course_id: "",
                title: "",
                start_at: "",
            },
            defaultItem: {
                course_id: "",
                title: "",
                start_at: "",
            },
            courseIdRules: [
                (v) =>
                    !!v || "Debe seleccionar un curso para la el master class",
            ],
        };
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Editar Master Class";
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },

    created() {
        this.list();
        this.listCourses();
    },

    methods: {
        copyLink(link) {
            if (window.isSecureContext && navigator.clipboard) {
                // protocol is https
                navigator.clipboard.writeText(link);
            } else {
                // protocol is http
                this.unsecuredCopyToClipboard(link);
            }
            this.showSnackbar();
        },
        unsecuredCopyToClipboard(content) {
            const textArea = document.createElement("textarea");
            textArea.value = content;
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                document.execCommand("copy");
            } catch (err) {
                console.error("Fallback: Oops, unable to copy", err);
            }
            document.body.removeChild(textArea);
        },

        showSnackbar() {
            this.snackbar = true;
        },
        list: async function () {
            try {
                const { data, status } = await apiAxios.get("mc/list");
                if (status === 200) {
                    this.items = data.list;
                    this.loading = false;
                }
            } catch (error) {
                console.log(error);
            }
        },
        listCourses: async function () {
            try {
                const { data, status } = await apiAxios.get("/mc/course/list");
                if (status === 200) {
                    this.courses = data;
                }
            } catch (error) {
                console.log(error);
            }
        },

        editItem(item) {
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.editedItem.date = moment(this.editedItem.start_at).format(
                "YYYY-MM-DD"
            );
            this.editedItem.time = moment(this.editedItem.start_at).format(
                "HH:mm"
            );
            this.dialog = true;
        },

        showAlertDialog: function (message) {
            this.message = message;
            this.showAlert = true;
            setTimeout(() => {
                this.message = "";
                this.showAlert = false;
            }, 3000);
        },
        deleteItem(item) {
            this.editedIndex = this.items.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm: async function () {
            let [status, message] = await this.delete(this.editedItem.id);
            console.log(status, message);
            if (status) {
                this.items.splice(this.editedIndex, 1);
                this.showAlertDialog(message);
            }
            this.closeDelete();
        },

        delete: async function (id) {
            try {
                const { data, status } = await apiAxios.delete(
                    `mc/delete/${id}`
                );
                if (status === 200) {
                    return [true, data.message];
                }
            } catch (error) {
                console.log(error);
            }
        },

        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.items[this.editedIndex], this.editedItem);
            } else {
                this.items.push(this.editedItem);
            }
            this.close();
        },
    },
};
</script>
<style>
.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #1de403;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
