<template>
    <div>
        <div
            class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"
        >
            <h1 class="h2">{{ title }}</h1>
            <div class="btn-toolbar mb-2 mb-md-0">
                <v-btn
                    v-if="page === 'list'"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="changePage('add')"
                >
                    <v-icon dark> mdi-plus </v-icon>
                </v-btn>
                <v-btn
                    v-if="page === 'add'"
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="changePage('list')"
                >
                    <v-icon dark> mdi-format-list-bulleted </v-icon>
                </v-btn>
                <!-- <div class="btn-group me-2">
                <button type="button" class="btn btn-sm btn-outline-secondary">
                    Share
                </button>
                <button type="button" class="btn btn-sm btn-outline-secondary">
                    Export
                </button>
            </div>
            <button
                type="button"
                class="btn btn-sm btn-outline-secondary dropdown-toggle"
            >
                <span data-feather="calendar"></span>
                This week
            </button> -->
            </div>
        </div>

        <div v-if="page === 'list'">
            <List></List>
        </div>
        <div v-if="page === 'add'">
            <Add></Add>
        </div>
    </div>
</template>
<script>
import Add from "@/components/master_class/Create.vue";
import List from "@/components/master_class/List.vue";

export default {
    components: {
        Add,
        List,
    },
    data() {
        return {
            title: "Lista de Master Class",
            page: "list",
        };
    },
    methods: {
        changePage(page) {
            this.page = page;
            this.title =
                page === "list"
                    ? "Lista de Master Class"
                    : "Crear Master Class";
        },
    },
};
</script>
