<template>
    <v-app>
        <v-card>
            <v-card-title>
                Participantes recientes
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-1"
                :search="search"
                loading-text="Cargando..."
                no-data-text="No se encontraron resultados"
                :loading="loading"
            >
                <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="sendMessage(item)"
                            >
                                <v-icon color="green"> mdi-whatsapp </v-icon>
                            </v-btn>
                        </template>
                        <span>Contactar</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </v-card>

        <v-alert v-if="showAlert" type="success" class="custom-alert">
            {{ message }}
        </v-alert>
    </v-app>
</template>
<script>
import { apiAxios } from "@/axios.config";
export default {
    data() {
        return {
            message: "",
            dateMenu: false,
            timeMenu: false,
            courses: [],
            showAlert: false,
            search: "",
            message: "",
            loading: true,
            dialog: false,
            dialogDelete: false,
            items: [],
            headers: [
                {
                    text: "id",
                    align: " d-none",
                    value: "id",
                },
                { text: "Curso", align: "start", value: "master_class.title" },
                { text: "Participante", value: "fullname" },
                { text: "Celular", value: "phone" },
                { text: "Correo", value: "email" },
                { text: "Acciones", value: "actions", sortable: false },
            ],
        };
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "New Item" : "Editar Master Class";
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        },
    },

    created() {
        this.list();
        this.listCourses();
    },

    methods: {
        sendMessage: function (item) {
            this.message = `Hola ${
                item.fullname
            }, te escribo desde el equipo de Master Class de Promolíder. Te informamos que tu inscripción a la Master Class ${
                item.master_class.title
            } ha sido confirmada.Te esperamos el día ${this.$moment(
                item.master_class.start_at
            ).format("DD MMMM hh:mm A")}.`;
            window.open(
                `https://wa.me/${item.phone}?text=${this.message}`,
                "_blank"
            );
        },
        list: async function () {
            try {
                const { data, status } = await apiAxios.get(
                    "/mc/participant/list"
                );
                if (status === 200) {
                    this.items = data;
                    this.loading = false;
                }
            } catch (error) {
                console.log(error);
            }
        },
        listCourses: async function () {
            try {
                const { data, status } = await apiAxios.get("/mc/course/list");
                if (status === 200) {
                    this.courses = data;
                }
            } catch (error) {
                console.log(error);
            }
        },
    },
};
</script>
<style>
.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #1de403;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
