import axios from 'axios'

//  common config
const token = localStorage.getItem('token');
axios.defaults.headers.post['Accept'] = 'application/json';
axios.defaults.headers.post["Content-Type"] = "application/json";

const mainAxios = axios.create({
    baseURL: ''
});

const env = process.env.MIX_APP_ENV;

const apiAxios = axios.create({
    baseURL:  env === 'local' ? 'http://localhost:8000/api/v1' : 'https://crm.promolider.org/api/v1',
});

window.apiAxios = apiAxios;

apiAxios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export {
    mainAxios,
    apiAxios
};