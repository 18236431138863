<template>
    <footer
        class="text-center text-lg-start text-white"
        style="background-color: #212529"
    >
        <section
            class="d-flex justify-content-center justify-content-lg-between p-4"
        >
            <div class="me-5 d-none d-lg-block">
                <span>Conéctate con nosotros en las redes sociales:</span>
            </div>

            <div class="icon-size">
                <a
                    target="_blank"
                    href="https://www.facebook.com/profile.php?id=100063926738412"
                    class="me-4 text-reset"
                >
                    <em class="bi bi-facebook"></em>
                </a>
                <a
                    target="_blank"
                    href="https://www.instagram.com/promoliderorg/?igshid=YmMyMTA2M2Y%3D"
                    class="me-4 text-reset"
                >
                    <em class="bi bi-instagram"></em>
                </a>
                <a
                    target="_blank"
                    href="https://www.linkedin.com/company/promolíder/mycompany/"
                    class="me-4 text-reset"
                >
                    <em class="bi bi-linkedin"></em>
                </a>
                <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCj-NmJXOnJt55aDRh1R0LLg"
                    class="me-4 text-reset"
                >
                    <em class="bi bi-youtube"></em>
                </a>
            </div>
        </section>

        <hr class="bg-white border-2 border-top border-white px-5 mx-5" />

        <section class="mt-5">
            <div class="container text-center text-md-start">
                <div class="row mt-3">
                    <div class="col-md-4 col-lg-4 col-xl-4 mx-auto mb-4">
                        <img alt="Logo" class="img-fluid p-2" :src="logo" />
                        <p class="text-justify">
                            Únete a nuestra maravillosa oportunidad y conoce personas extraordinarias alrededor del mundo.
                        </p>
                        <p class="text-justify">
                            Los miembros de nuestra red disfrutan de
                            entrenamientos, capacitacion acelerada y apoyo
                            especializado. En Promolíder la consigna es: "Juntos
                            lograremos monetizar tus conocimientos y
                            experiencia".
                        </p>
                    </div>

                    <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                        <!-- Links -->
                        <h6 class="text-uppercase fw-bold mb-4 mt-5 text-green">
                            Enlaces útiles
                        </h6>
                        <p>
                            <a href="http://vcr.promolider.org" class="text-reset">Aula virtual</a>
                        </p>

                         <!--
                        <p>
                            <a href="#!" class="text-reset"
                                >Nuestras tecnologias</a
                            >
                        </p>--> 

                        <p>
                            <a href="http://crm.promolider.org" class="text-reset">CRM</a>
                        </p>
                        <p>
                            <a href="http://nft.promolider.org" class="text-reset">NFT's</a>
                        </p>

                        <!--
                        <p>
                            <a href="#!" class="text-reset">Sobre nosotros</a>
                        </p>--> 
                        
                    </div>

                    <div
                        class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4"
                    >
                        <h6 class="text-uppercase fw-bold mb-4 mt-5 text-green">
                            Contáctanos
                        </h6>
                        <p>
                            <em class="bi bi-house-fill"></em> Av. La Fontana,
                            440, La Molina, Lima
                        </p>
                        <p>
                            <em class="bi bi-envelope-fill"></em>
                            promoliderorg@gmail.com
                        </p>
                        <p>
                            <em class="bi bi-telephone-fill"></em> +51 (01)
                            4833997
                        </p>
                        <p>
                            <em class="bi bi-telephone-fill"></em> +51 995668600
                        </p>
                    </div>
                </div>
            </div>
        </section>
        <div
            class="text-center p-4"
            style="background-color: rgba(0, 0, 0, 0.05)"
        >
            © {{ new Date().getFullYear() }} Copyright:
            <a class="text-reset fw-bold" href="https://promolider.org/"
                >promolider.org</a
            >
        </div>
    </footer>
</template>
<script>
import logo from "@assets/images/logo.webp";
export default {
    data() {
        return {
            logo,
        };
    },
};
</script>
<style>
.icon-size > a {
    font-size: 1.2rem;
}

.text-green {
    color: #1ae600;
    font-weight: 700;
}
</style>
