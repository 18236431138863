<template>
    <v-app>
        <PublicHeader app></PublicHeader>
        <v-main>
            <v-sheet color="dark_promolider">
                <v-container fluid>
                    <v-row class="d-flex justify-center mt-5">
                        <v-col cols="7" align="center">
                            <h2 class="fw-bolder text-green fs-xl">
                                Próximas Master Classes
                            </h2>
                            <p class="fs-4 text-gray">
                                Aprovecha al máximo todos los conocimientos de
                                nuestros expositores
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="d-flex justify-center">
                            <v-sheet
                                class="mx-auto my-5"
                                max-width="60%"
                                color="dark_promolider"
                            >
                                <v-slide-group
                                    show-arrows
                                    center-active
                                    v-model="selectedMonth"
                                >
                                    <v-slide-item
                                        v-for="(month, index) in months"
                                        :key="index"
                                        v-slot="{ active, toggle }"
                                    >
                                        <v-btn
                                            class="mx-2"
                                            :input-value="active"
                                            active-class="purple white--text"
                                            depressed
                                            rounded
                                            @click="toggle"
                                        >
                                            {{ month.month }}
                                        </v-btn>
                                    </v-slide-item>
                                </v-slide-group>
                            </v-sheet>
                        </v-col>
                    </v-row>

                    <v-row v-if="loading">
                        <v-col cols="12" class="d-flex justify-center">
                            <v-progress-circular
                                indeterminate
                                color="green"
                            ></v-progress-circular>
                        </v-col>
                    </v-row>
                    <v-row v-else>
                        <v-col
                            v-if="filterList.length === 0"
                            cols="12"
                            class="d-flex justify-center"
                        >
                            <h2 class="fw-bolder text-white">
                                No hay master classes para este mes
                            </h2>
                        </v-col>

                        <v-col
                            v-else
                            class="onHover"
                            :class="index % 2 == 0 ? 'offset-md-1' : ''"
                            cols="5"
                            v-for="(item, index) in filterList"
                            :key="index"
                        >
                            <v-card
                                class="mx-auto"
                                max-width="440px"
                                color="#2b3233"
                            >
                                <v-img
                                    :src="item.banner"
                                    contain
                                    max-height="440px"
                                    min-height="440px"
                                ></v-img>

                                <v-card-title class="text-white fw-bolder">
                                    {{ item.title }}
                                </v-card-title>

                                <v-card-subtitle class="text-gray">
                                    <v-row>
                                        <v-col cols="6">
                                            {{
                                                $moment(item.date).format(
                                                    "DD MMMM hh:mm A"
                                                )
                                            }}
                                        </v-col>
                                        <v-col cols="6" align="right">
                                            <v-speed-dial
                                                v-model="item.dialShare"
                                                direction="top"
                                                transition="slide-y-reverse-transition"
                                            >
                                                <template v-slot:activator>
                                                    <v-btn
                                                        fab
                                                        top
                                                        small
                                                        color="green"
                                                    >
                                                        <v-icon
                                                            style="color: black"
                                                            v-if="
                                                                item.dialShare
                                                            "
                                                            >mdi-close</v-icon
                                                        >
                                                        <v-icon
                                                            v-else
                                                            style="color: black"
                                                            >mdi-share-variant</v-icon
                                                        >
                                                    </v-btn>
                                                </template>
                                                <div class="share-network-list">
                                                    <ShareNetwork
                                                        v-for="network in networks"
                                                        :network="
                                                            network.network
                                                        "
                                                        :key="network.network"
                                                        :style="{
                                                            backgroundColor:
                                                                network.color,
                                                        }"
                                                        :url="sharing.url"
                                                        :title="item.title"
                                                        :description="
                                                            sharing.description
                                                        "
                                                        :quote="item.title"
                                                        :hashtags="item.title"
                                                        :twitterUser="
                                                            sharing.twitterUser
                                                        "
                                                    >
                                                        <em
                                                            :class="
                                                                network.icon
                                                            "
                                                        ></em>
                                                        <span>{{
                                                            network.name
                                                        }}</span>
                                                    </ShareNetwork>
                                                </div>
                                            </v-speed-dial>
                                        </v-col>
                                    </v-row>
                                </v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-sheet>
        </v-main>
        <PublicFooter app></PublicFooter>
    </v-app>
</template>
<script>
import { apiAxios } from "@/axios.config";
import PublicHeader from "@/components/partials/Header.vue";
import PublicFooter from "@/components/partials/Footer.vue";

export default {
    mounted() {
        this.recentMasterClasses();
        this.selectedMonth = this.$moment().month();
    },
    computed: {
        routes() {
            return {
                home: this.homeRoute,
                login: this.loginRoute,
            };
        },
    },
    components: {
        PublicHeader,
        PublicFooter,
    },
    name: "MasterClasses",
    data: () => ({
        sharing: {
            url: "https://mc.promolider.org",
            title: "",
            description: "",
            quote: "",
            hashtags: "",
        },

        networks: [
            {
                network: "facebook",
                name: "Facebook",
                icon: "fab fah fa-lg fa-facebook-f",
                color: "#1877f2",
            },
            {
                network: "linkedin",
                name: "LinkedIn",
                icon: "fab fah fa-lg fa-linkedin",
                color: "#007bb5",
            },

            {
                network: "telegram",
                name: "Telegram",
                icon: "fab fah fa-lg fa-telegram-plane",
                color: "#0088cc",
            },
            {
                network: "twitter",
                name: "Twitter",
                icon: "fab fah fa-lg fa-twitter",
                color: "#1da1f2",
            },
            {
                network: "whatsapp",
                name: "Whatsapp",
                icon: "fab fah fa-lg fa-whatsapp",
                color: "#25d366",
            },
        ],
        filterList: [],
        loading: true,
        months: [
            { month: "Enero" },
            { month: "Febrero" },
            { month: "Marzo" },
            { month: "Abril" },
            { month: "Mayo" },
            { month: "Junio" },
            { month: "Julio" },
            { month: "Agosto" },
            { month: "Septiembre" },
            { month: "Octubre" },
            { month: "Noviembre" },
            { month: "Diciembre" },
        ],
        selectedMonth: 0,
    }),
    methods: {
        recentMasterClasses: async function () {
            const { status, data } = await apiAxios.post("/mc/filter");
            if (status === 200) {
                this.filterList = data.list;
                this.loading = false;
            }
        },
    },
    watch: {
        selectedMonth: async function (val) {
            this.loading = true;
            const { status, data } = await apiAxios.post("/mc/filter", {
                month: val + 1,
            });
            if (status === 200) {
                this.filterList = data.list;
                this.loading = false;
            }
        },
    },
};
</script>
<style>
.text-green {
    color: var(--v-green-base) !important ;
}
.text-gray {
    color: var(--v-gray-base) !important ;
}
.fs-xl {
    font-size: 3.7em;
}
.theme--light.v-icon {
    color: var(--v-green-base);
}
.theme--light.v-icon.v-icon.v-icon--disabled {
    color: var(--v-gray-base) !important ;
}
.onHover:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
}
.share-network-list > a {
    flex: none;
    color: #ffffff !important;
    background-color: #333;
    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 10px 10px 0;
    text-decoration: none !important;
}

.share-network-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1000px;
    margin: auto;
}
a[class^="share-network-"] .fah {
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10px;
    flex: 0 1 auto;
}
a[class^="share-network-"] span {
    padding: 0 10px;
    flex: 1 1;
    font-weight: 500;
}
</style>
