<template>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark static-top">
        <div class="container">
            <a class="navbar-brand" href="#">
                <img alt="Promolíder logo" height="36" :src="logo" />
            </a>
            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <a
                            class="nav-link active"
                            aria-current="page"
                            :href="homeRoute"
                            >Inicio</a
                        >
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link active" aria-current="page" href="#"
                            >Contacto</a
                        >
                    </li> -->
                    <li class="nav-item">
                        <a
                            class="nav-link active"
                            aria-current="login"
                            :href="loginRoute"
                            >Iniciar sesión</a
                        >
                    </li>
                    <!-- <li class="nav-item">
                    <a class="nav-link" href="#">Master Class Disponibles</a>
                </li> -->
                    <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Dropdown
                    </a>
                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                        <li><a class="dropdown-item" href="#">Action</a></li>
                        <li><a class="dropdown-item" href="#">Another action</a></li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>
                        <li><a class="dropdown-item" href="#">Something else here</a></li>
                    </ul>
                </li> -->
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
import logo from "@assets/images/logo.webp";

export default {
    name: "public-header",
    props: {
        homeRoute: {
            type: String,
            default: "/",
        },
        loginRoute: {
            type: String,
            default: "/login",
        },
    },
    data() {
        return {
            logo,
        };
    },
};
</script>
